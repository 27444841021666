<template>
  <div class="userList-list">
    <app-list :opt="opt" @get="onGet" ref="appList"></app-list>
    <el-dialog top="30vh" width="500px" title="注销账号" :visible.sync="logoutDialogVisible"  :close-on-click-modal="false">
        <div class="logout-body">
            <div>您正在操作注销账号，确定要继续注销吗？</div>
            <!-- <el-input placeholder="请输入登录密码" v-model.trim="password" type="text" class="no-autofill-pwd"></el-input> -->
        </div>
        <div class="logout-btn">
            <el-button @click="logoutDialogVisible = false">取消</el-button>
            <el-button type="primary" @click="logoutAccount()">注销</el-button>
        </div>
    </el-dialog>
    <el-dialog top="30vh" width="500px" title="审核注销" :visible.sync="auditDialogVisible"  :close-on-click-modal="false">
        <div class="logout-body">
            <div>您正在操作审核注销账号，确定同意注销用户吗？</div>
            <!-- <el-input placeholder="请输入登录密码" v-model.trim="password2" type="text" class="no-autofill-pwd"></el-input> -->
        </div>
        <div class="logout-btn">
            <el-button @click="openRejectDialog()">驳回</el-button>
            <el-button type="primary" @click="auditAccount()">同意注销</el-button>
        </div>
    </el-dialog>
    <el-dialog top="30vh" width="500px" title="驳回注销" :visible.sync="rejectDialogVisible"  :close-on-click-modal="false">
        <div class="logout-body">
            <div>请填写驳回理由，不超过20个字。</div>
            <el-input placeholder="请输入驳回理由" v-model="reason" type="textarea"  maxlength="20" show-word-limit :rows="1"></el-input>
        </div>
        <div class="logout-btn">
            <el-button @click="rejectDialogVisible = false">取消</el-button>
            <el-button type="primary" @click="rejectAccount()">驳回</el-button>
        </div>
    </el-dialog>
    <el-dialog top="30vh" width="500px" title="修改手机号码" :visible.sync="editPhoneDialogVisible"  :close-on-click-modal="false">
        <div class="logout-body">
            <div>{{ realName }}，原手机号码：{{ phoneNum }}，请输入新手机号码：</div>
            <el-input placeholder="请输入新手机号码" v-model.trim="phone" type="text" maxlength="11"></el-input>
        </div>
        <div class="logout-btn">
            <el-button @click="editPhoneDialogVisible = false">取消</el-button>
            <el-button type="primary" @click="editPhone()">修改</el-button>
        </div>
    </el-dialog>
    <el-dialog top="30vh" width="500px" title="重置密码" :visible.sync="resetPswVisible"  :close-on-click-modal="false">
        <div class="logout-body">
            <div>重置密码后，密码为系统设置的初始密码：Pw123456，确定要重置用户密码吗？</div>
        </div>
        <div class="logout-btn">
            <el-button @click="resetPswVisible = false">取消</el-button>
            <el-button type="primary" @click="resetPsw()">确定</el-button>
        </div>
    </el-dialog>
  </div>
</template>
<script>
let validName = (rule, value, callback) => {
    if (value == "" || value == undefined) {
      callback();
    } else {
      //姓名支持输入中英文字母，汉字，1~16个字符
      let reg = /^[a-zA-Z\u4e00-\u9fa5]{1,16}$/;
      if (!reg.test(value)) {
        callback(new Error("姓名格式不对"));
      } else {
        callback();
      }
    }
  };
  let validMobile = (rule, value, callback) => {
    if (value == "" || value == undefined) {
      callback();
    } else {
      let reg = /^1[3456789]\d{9}$/;
      if (!reg.test(value)) {
        callback(new Error("电话号码格式不对"));
      } else {
        callback();
      }
    }
  };
  let validIdNumber = (rule, value, callback) => {
    if (value == "" || value == undefined) {
      callback();
    } else {
      //证件号码仅支持数字，大小写字母，1~18位
      let reg = /^[A-z0-9]{1,18}$/;
      if (!reg.test(value)) {
        callback(new Error("证件号码格式不对"));
      } else {
        callback();
      }
    }
  };
  let validCity = (rule, value, callback) => {
    if (value == "" || value == undefined) {
      callback();
    } else {
      //居住城市仅汉字，1~24个字符
      let reg = /^[\u4E00-\u9FA5]{1,24}$/;
      if (!reg.test(value)) {
        callback(new Error("居住城市格式不对"));
      } else {
        callback();
      }
    }
  };
  export default {
    data() {
      let _this = this;
      return {
        rentalStyle: "0", // 0长租 1短租
        logoutDialogVisible: false,
        auditDialogVisible: false,
        rejectDialogVisible: false,
        editPhoneDialogVisible: false,
        resetPswVisible: false,
        password: '',
        password2: '',
        reason: '',
        phone: '',
        realName: '',
        phoneNum: '',
        rowData: [],
        rowData2: [],
        rowData3: [],
        resetPswId: '',
        opt: {
          title: "用户列表",
          search: [{
              key: "realName",
              label: "姓名",
              maxlength: 16,
              rules: [{
                validator: validName,
                trigger: ['blur']
              }]
            },
            {
              key: "phoneNum",
              label: "手机号码",
              maxlength: 11,
              rules: [{
                validator: validMobile,
                trigger: ['blur']
              }]
            },
            {
              key: "idType",
              label: "证件类型",
              type: "select-no-filterable",
              opt: {
                list: [{
                    value: "",
                    label: "全部"
                  },
                  ..._this.certTypeOptions
                ]

              }
            },
            {
              key: "idCardNo",
              label: "证件号码",
              maxlength: 18,
              rules: [{
                validator: validIdNumber,
                trigger: ['blur']
              }]
            },
            {
              key: "city",
              label: "居住城市",
              maxlength: 24,
              rules: [{
                validator: validCity,
                trigger: ['blur']
              }]
            },
            {
              key: "userType",
              label: "用户类型",
              type: "select-no-filterable",
              opt: {
                list: _this.tenantUserOption
              }
            },
            {
              key: "cancelState",
              label: "账号状态",
              type: "select-no-filterable",
              opt: {
                list: [{
                    label: "正常",
                    value: "0"
                  },
                  {
                    label: "注销中",
                    value: "1"
                  },
                  {
                    label: "已注销",
                    value: "2"
                  }
                ]
              }
            },
            {
              key: "date",
              label: "注册时间",
              type: "data-range"
            },
          ],
          columns: [{
              label: "姓名",
              key: "realName",
              width: 120,
            },
            {
              label: "性别",
              key: "sexStr",
              width: 120
            },
            {
              label: "居住城市",
              key: "city"
            },
            {
              label: "手机号码",
              key: "phoneNum"
            },
            {
              label: "证件类型",
              key: "cardTypeStr"
            },
            {
              label: "证件号码",
              key: "idCardNoStr"
            },
            {
              label: "用户类型",
              key: "userTypeStr"
            },
            {
              label: "注册时间",
              key: "createdDate"
            },
            {
              label: "账号状态",
              key: "cancelStateStr"
            },
            {
                label: "操作",
                key: "action",
                type: "actionStatusList",
                opt: {
                    ativeStatus: ["注销账号" , "审核注销"],
                    on (row) {
                      if(row.cancelState == 0) {
                        _this.openLogoutDialog(row)
                      }else if (row.cancelState == 1){
                        _this.openAuditDialog(row)
                      }
                    }
                    // list: [{
                    //     label: "注销账号",
                    //     on(row) {
                    //       _this.openLogoutDialog(row)
                    //     }
                    // },{
                    //     label: "审核账号",
                    //     on(row) {
                    //       _this.openAuditDialog(row)
                    //     }
                    // }]
                }
            }
          ],
          buttons: [{
              type: 1,
              name: '修改手机号码',
              on(data) {
                if (data.data.cancelState == 2 ) {
                    return _this.$message({
                        showClose: true,
                        message: "已注销账号不能修改！",
                        type: "warning"
                    });
                }
                _this.editPhoneDialogVisible = true;
                _this.rowData3 = data.data;
                _this.phoneNum = data.data.phoneNum;
                _this.realName = data.data.realName;
                _this.phone = '';
              }
          },{
                type: 1,
                icon: 'question',
                color: 'primary',
                name: '重置密码',
                on(data) {
                  if (data.data.cancelState == 2 ) {
                      return _this.$message({
                          showClose: true,
                          message: "已注销账号不能重置密码！",
                          type: "warning"
                      });
                  }
                  _this.resetPswVisible = true
                  _this.resetPswId = data.data.userId
                }
          }],
        }
      };
    },
    created() {
      console.log("tenant-list created!!");

    },
    activated() {
      this.getMenuName(this.$route.path);
    },
    deactivated() {
        this.logoutDialogVisible = false;
        this.auditDialogVisible = false;
        this.rejectDialogVisible = false;
        this.resetPswVisible = false;
    },
    methods: {
      onGet(opt) {
        let dto = {
          pageNum: opt.skip,
          pageSize: opt.limit,
          startTime: opt.searchForm['date'] ? this.format(opt.searchForm['date'][0]) : null,
          endTime: opt.searchForm['date'] ? moment(opt.searchForm['date'][1]).format("YYYY-MM-DD 23:59:59") : null,
          ...opt.searchForm,
        };
        let url = "user-service/v1/user/page";
        this.post(url, dto, {
          isUseResponse: true
        }).then(res => {
          if (res.data.data.data) {
            for (let i = 0; i < res.data.data.data.length; i++) {
              let item = res.data.data.data[i];
              item.userTypeStr = item.authState!=null ? ['实名用户', '实名用户', '实名用户','普通用户'][item.authState] : '普通用户';
              item.realName = item.realName ? item.realName : '--';
              item.idCardNoStr = item.idCardNo ? item.idCardNo : '--';
              item.city = item.city ? item.city : '--';
              item.phoneNum = item.phoneNum ? item.phoneNum : '--';
              item.createdDate = item.createdDate ? item.createdDate : '--';
              item.cancelStateStr = ["正常","注销中","已注销"][item.cancelState];
              //1-男 2-女 其他值都显示--
              if(item.gender == 1) {
                item.sexStr = '男';
              }else if(item.gender == 2) {
                item.sexStr = '女';
              }else {
                item.sexStr = '--';
              }
              if(item.idType && ['1','2','3','4','5','6','7'].indexOf(item.idType+'')!=-1){
                  item.cardTypeStr=this.certTypeArr[item.idType]
              }
              else{
                item.cardTypeStr=item.cardStrType?this.certTypeArr[this.cardStrToNum(item.cardStrType)]:'--';
              }
              if(item.cancelState == 0) {
                item.action= "注销账号"
              }else if(item.cancelState == 1) {
                item.action = "审核注销"
              }else {
                item.action = "--"
              }
            }
          } else {
            res.data.data.data = [];
            res.data.data.total = 0;
          }
          opt.cb(res.data.data);
        });
      },
      // 合同id获取房间id
      getTalentInfo(id) {
        let dto = {};
        if (this.rentalStyle == "0") {
          dto.contractId = id;
        } else {
          dto.id = id;
        }
        let url =
          this.rentalStyle == "0" ?
          "tenant-service/leasecontract/queryTalentInfo" :
          "landlord-service/shortRent/getShortRent";
        return this.post(url, dto).then(data => {
          return data.roomId || "";
        });
      },
      //获取房源列表
      getApartmentList() {
        var dto = {
          pageNumber: "1",
          pageSize: "99999",
          landlordId: this.cache.getLS("userInfo")["roleType"] == "1" ?
            null : this.cache.getLS("userInfo")["userId"]
        };
        this.post("landlord-service/apartment/queryPagesApartment", dto).then(
          res => {
            let returnData = {};
            res.forEach(data => {
              returnData[data.id] = data.name;
            });
            res = JSON.parse(JSON.stringify(res).replace(/name/g, "label"));
            res = JSON.parse(JSON.stringify(res).replace(/id/g, "value"));
            this.opt.search[2]["opt"]["list"] = res;
          }
        );
      },
      // 打开注销账号弹窗
      openLogoutDialog(data) {
        this.password = "";
        this.logoutDialogVisible = true;
        this.rowData = data
      },
      // 注销
      logoutAccount() {
          // if(!this.password) {
          //     return this.$message({
          //         message: "请先输入密码",
          //         type: "warning"
          //     });
          // }
          // let dto = {
          //     password: this.AES.encrypt(this.password)
          // }
          // this.post("user-service/v1/user/check-pwd",dto,{
          //     isUseResponse: true
          // }).then(res =>{
          //   if(res.data.data){
          this.post("user-service/user/config/logoff-user/" + this.rowData.userId).then(res => {
              this.logoutDialogVisible = false;
              this.$refs.appList.get();
              return this.$message({
                  message: "注销成功",
                  type: "success"
              });
          })
          //   }else{
          //       return this.$message({
          //           message: "密码错误",
          //           type: "warning"
          //       });
          //   }
          // })
      },
      // 打开审核注销弹窗
      openAuditDialog(data) {
        this.password2 = "";
        this.auditDialogVisible = true;
        this.rowData2 = data
      },
      // 审核注销
      auditAccount() {
          // if(!this.password2) {
          //     return this.$message({
          //         message: "请先输入密码",
          //         type: "warning"
          //     });
          // }
          // let dto = {
          //     password: this.AES.encrypt(this.password2)
          // }
          // this.post("user-service/v1/user/check-pwd",dto,{
          //     isUseResponse: true
          // }).then(res =>{
          //   if(res.data.data){
          this.post("user-service/user/config/logoff-user/" + this.rowData2.userId).then(res => {
              this.auditDialogVisible = false;
              this.$refs.appList.get();
              return this.$message({
                  message: "注销成功",
                  type: "success"
              });
          })
          //   }else{
          //       return this.$message({
          //           message: "密码错误",
          //           type: "warning"
          //       });
          //   }
          // })
      },
      // 打开驳回注销弹窗
      openRejectDialog() {
        this.rejectDialogVisible = true;
        this.reason = ''
      },
      // 驳回注销
      rejectAccount() {
        if(!this.reason) {
            return this.$message({
                message: "请填写驳回理由",
                type: "warning"
            });
        }
        let dto = {
          reason: this.reason,
          userId: this.rowData2.userId
        }
        this.post("user-service/user/config/apply-for-cancel-logoff",dto,{
          isUseResponse: true
        }).then(res => {
            this.auditDialogVisible = false;
            this.rejectDialogVisible = false
            this.$refs.appList.get();
            return this.$message({
                message: "驳回成功",
                type: "success"
            });
        })
      },

      // 修改手机号
      editPhone() {
        if(!this.phone) {
            return this.$message({
                message: "请输入新手机号码",
                type: "warning"
            });
        }
        if(!this.phone.match(/^1[3456789]\d{9}$/)) {
            return this.$message({
                message: "请输入正确的手机号码",
                type: "warning"
            });
        }
        let dto = {
          userId: this.rowData3.userId,
          phone: this.phone
        }
        this.post("user-service/user/config/modify-user-phone",dto,{
          isUseResponse: true
        }).then(res => {
            this.editPhoneDialogVisible = false;
            this.$refs.appList.get();
            return this.$message({
                message: "修改成功",
                type: "success"
            });
        })
      },
      // 重置密码
      resetPsw() {
        let dto = {
          userId: this.resetPswId
        }
        this.post("user-service/v1/user/reset-password",dto,{
          isUseResponse: true
        }).then(res =>{
          if(res.data.data){
            this.resetPswVisible = false
            return this.$message({
                message: "重置密码成功",
                type: "success"
            });
          }
        })
      }
    }
  };
</script>
<style lang="scss" scoped>
.logout-body{
    margin: -15px 10px 20px;
    height: 80px;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
}
.logout-btn{
    // display: flex;
    // justify-content: end;
    margin-top: 10px;
    text-align: right;
}
.no-autofill-pwd {
    /deep/ .el-input__inner {
        -webkit-text-security: disc !important;
    }
}
</style>
